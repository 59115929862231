import { createTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import { slimWidth } from "./constants";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "Birch",
    ].join(","),
    h1: {
      fontSize: 40,
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    h2: {
      fontSize: 28,
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    h3: {
      fontFamily: "Helvetica",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "left",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    h4: {
      fontFamily: "Helvetica",
      fontSize: 24,
      textAlign: "left",
      maxWidth: slimWidth,
    },
    body1: {
      fontFamily: "Helvetica",
      textAlign: "left",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
    body2: {
      fontSize: 12,
      fontFamily: "Helvetica",
      textAlign: "left",
      maxWidth: slimWidth,
      marginRight: "auto",
      marginLeft: "auto",
    },
  },
  palette: {
    primary: {
      main: grey[500],
      light: grey[300],
      dark: grey[700],
      contrastText: "#fff",
    },
    background: {
      default: "white",
    },
  },
});

export default theme;
