import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  ListItemText,
  ListItem,
  IconButton,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  headerHeight,
  slimWidth,
  navbarDrawerWidth,
  swapNavbarWidth,
} from "../constants";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";
import { grey } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";

const activeButtonColor = "#eeeeee";
const toolBarMaxWidth = slimWidth - 48;

const useStyles = makeStyles({
  root: {
    height: headerHeight,
  },
  appBar: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    zIndex: 9999,
  },
  toolBar: {
    maxWidth: toolBarMaxWidth,
    width: "100%",
    justifyContent: "flex-end",
    height: headerHeight,
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawer: {
    width: navbarDrawerWidth,
    flexShrink: 0,
  },
  listWrapper: {
    paddingTop: 0,
  },
  drawerPaper: {
    width: navbarDrawerWidth,
    paddingTop: headerHeight,
  },
  appBarDivider: {
    width: "100%",
  },
  link: { textDecoration: "none", color: "black" },
  menuButton: {
    marginRight: "16px",
  },
  menuIcon: {
    color: "black",
  },
  desktopLogo: { flexGrow: 1 },
  mobileLogo: { flexGrow: 1, paddingLeft: "16px" },
  submenuItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteIcon: {
    color: grey[400],
    width: "1rem",
    height: "1rem",
  },
});

function MenuItems({ menuItems, setOpen, handleDeleteFeed }) {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <List className={classes.listWrapper}>
      {menuItems.map((item) =>
        item.submenu ? (
          <Link to={item.key} key={item.key} className={classes.link}>
            <ListItem
              key={item.key}
              onClick={() => {
                setOpen(false);
              }}
              style={{
                backgroundColor: pathname === item.key && activeButtonColor,
                paddingLeft: "2rem",
              }}
              className={classes.submenuItem}
            >
              <ListItemText
                primary={item.name}
                classes={{ primary: classes.listItem }}
              />
              {item.uuid && (
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(e) => handleDeleteFeed(e, item.uuid)}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              )}
            </ListItem>
          </Link>
        ) : item.header ? (
          <ListItem key={item.key} className={classes.listItem}>
            <ListItemText primary={item.name} />
          </ListItem>
        ) : (
          <Link to={item.key} key={item.key} className={classes.link}>
            <ListItem
              key={item.key}
              onClick={() => {
                setOpen(false);
              }}
              style={{
                backgroundColor: pathname === item.key && activeButtonColor,
              }}
            >
              <ListItemText
                primary={item.name}
                classes={{ primary: classes.listItem }}
              />
            </ListItem>
          </Link>
        )
      )}
    </List>
  );
}

function DesktopNavbar({ menuItems, handleDeleteFeed }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography
            variant="h3"
            component="div"
            className={classes.desktopLogo}
          >
            <Link to="/" className={classes.link}>
              Pod Reader
            </Link>
          </Typography>
        </Toolbar>
        <Divider className={classes.appBarDivider} />
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="right"
        open={true}
        classes={{ paper: classes.drawerPaper }}
      >
        <MenuItems
          menuItems={menuItems}
          setOpen={() => {}}
          handleDeleteFeed={handleDeleteFeed}
        />
      </Drawer>
    </div>
  );
}

function MobileNavbar({ menuItems, handleDeleteFeed }) {
  const [open, setOpen] = React.useState(false);
  const { width } = useWindowDimensions();

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography
            variant="h3"
            component="div"
            className={
              width > swapNavbarWidth ? classes.desktopLogo : classes.mobileLogo
            }
          >
            <Link to="/" className={classes.link}>
              Pod Reader
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            className={width <= swapNavbarWidth && classes.menuButton}
            onClick={() => setOpen(!open)}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </Toolbar>
        <Divider className={classes.appBarDivider} />
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{ onBackdropClick: () => setOpen(false) }}
      >
        <MenuItems
          menuItems={menuItems}
          setOpen={setOpen}
          handleDeleteFeed={handleDeleteFeed}
        />
      </Drawer>
    </div>
  );
}

export default function Navbar() {
  const { width } = useWindowDimensions();
  const { user } = useContext(UserContext);
  const { feeds, removeFeed, activeFeed } = useContext(FeedContext);
  const history = useHistory();
  const handleDeleteFeed = (e, uuid) => {
    e.preventDefault();
    e.stopPropagation();
    removeFeed(uuid).then(() => {
      if (!activeFeed || activeFeed.uuid === uuid) {
        history.push("/feed/first");
      }
    });
  };

  const loggedInMenuItems = [
    { key: "/", name: "Podcasts", header: true },
    ...feeds.map((feed) => ({
      key: `/feed/${feed.uuid}`,
      name: feed.name,
      uuid: feed.uuid,
      submenu: true,
    })),
    { key: "/feed/new", name: "Add New Podcast", submenu: true },
    { key: "/login", name: "Account" },
  ];
  const loggedOutMenuItems = [
    { key: "/", name: "Home" },
    { key: "/login", name: "Login" },
  ];
  const menuItems = isLoggedIn(user) ? loggedInMenuItems : loggedOutMenuItems;

  return (
    <div>
      {width > swapNavbarWidth && isLoggedIn(user) ? (
        <DesktopNavbar
          menuItems={menuItems}
          handleDeleteFeed={handleDeleteFeed}
        />
      ) : (
        <MobileNavbar
          menuItems={menuItems}
          handleDeleteFeed={handleDeleteFeed}
        />
      )}
    </div>
  );
}
