import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Typography } from "@material-ui/core";
import PricingOptions from "./PricingOptions.js";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  ctaButtonGroupWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
  },
  ctaButton: { marginLeft: "0.5rem", marginRight: "0.5rem" },
});

export default function LandingPageContent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h1" gutterBottom>
        Podcasting, without all the talking
      </Typography>
      <Typography variant="h4" gutterBottom>
        Create your own podcast by just typing or pasting a text script, and Pod
        Reader gives you a hosted podcast with a natural sounding AI voice.
      </Typography>

      <div className={classes.ctaButtonGroupWrapper}>
        <Button
          href="/login?signup=true&subscription_type=free"
          variant="contained"
          className={classes.ctaButton}
          color="success"
        >
          Try for free
        </Button>
        <Button href="/login" variant="contained" className={classes.ctaButton}>
          Login
        </Button>
      </div>

      <PricingOptions />
    </div>
  );
}
