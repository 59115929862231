import { refreshToken } from "./signup_or_login";
import { BASE_URL } from "../constants";

export function makeAuthenticatedRequest(endpoint, method, body = null) {
  // Wrap the function in a promise, so the caller can choose to wait or not
  return new Promise((resolve, reject) => {
    (async () => {
      try {
        const url = `${BASE_URL}/${endpoint}`;
        const isFormData = body instanceof FormData;
        const requestOptions = {
          method: method,
          headers: {
            ...(isFormData ? {} : { "Content-Type": "application/json" }),
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: body ? (isFormData ? body : JSON.stringify(body)) : null,
        };

        let response = await fetch(url, requestOptions);
        response = await handleResponse(response, requestOptions);

        if (response.status === 204) {
          // Handle 204 No Content
          resolve({ message: "success", status: 204 });
          return;
        }

        const responseData = await response.json();
        resolve(responseData);
      } catch (error) {
        reject(error);
      }
    })();
  });
}

async function handleResponse(response, requestOptions) {
  if (!response.ok) {
    if (response.status === 401) {
      await attemptTokenRefresh(requestOptions);
      // Retry the original request after token refresh
      response = await fetch(requestOptions.url, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      return response;
    } else {
      throw new Error("Network response was not ok.");
    }
  }
  return response;
}

async function attemptTokenRefresh(requestOptions) {
  try {
    const newAccessToken = await refreshToken();
    localStorage.setItem("access_token", newAccessToken);

    // Update the authorization header with the new access token
    requestOptions.headers.Authorization = `Bearer ${newAccessToken}`;
  } catch (error) {
    throw new Error("Failed to refresh access token.");
  }
}
