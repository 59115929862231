import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Box, TextField, Button } from "@material-ui/core";
import { MAX_CHARS_PER_EPISODE, MIN_CHARS_PER_EPISODE } from "../constants";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formItem: {
    marginTop: "1rem",
  },
});

export default function AddEpisodeForm({ onSubmit, addingEpisode }) {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const usageCount = user.account.usage_count;
  const usageLimit = user.account.usage_limit;
  const usageLeft = usageLimit - usageCount;
  const maxCharsToAdd = Math.min(usageLeft, MAX_CHARS_PER_EPISODE);

  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({ title, text }); // Calling the passed function
  };

  const handleTextChange = (event) => {
    event.preventDefault();
    const numChars = event.target.value.length;

    if (usageLeft < MIN_CHARS_PER_EPISODE) {
      return;
    }
    if (numChars > maxCharsToAdd) {
      return;
    }
    setText(event.target.value);
  };

  useEffect(() => {
    // Emptying form after finished waiting for episode
    // TODO: Only clear the form if the episode was successful
    if (!addingEpisode) {
      setTitle("");
      setText("");
    }
  }, [addingEpisode]);

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <TextField
            required
            id="episode-title-input"
            label="Title"
            type="text"
            fullWidth
            className={classes.formItem}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            required
            id="episode-text-input"
            label="Text"
            type="text"
            multiline
            fullWidth
            minRows={4}
            maxRows={20}
            className={classes.formItem}
            value={text}
            onChange={handleTextChange}
            helperText={
              usageLeft > MIN_CHARS_PER_EPISODE ? (
                <>
                  {`${text.length} of max ${maxCharsToAdd} characters`}
                  <br />
                  {`Write at least ${MIN_CHARS_PER_EPISODE} characters to create episode`}
                </>
              ) : (
                <>
                  {`${usageLeft} characters left this month`}
                  <br />
                  {`You need at least ${MIN_CHARS_PER_EPISODE} characters to create an episode`}
                </>
              )
            }
          />
          <Button
            type="submit"
            variant="contained"
            className={classes.formItem}
            disabled={addingEpisode || text.length < MIN_CHARS_PER_EPISODE}
          >
            {"Add episode"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
