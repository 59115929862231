import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles({
  root: { marginTop: "2rem", marginBottom: "2rem" },
  header: { marginBottom: "1.2rem" },
  card: {
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
    height: 286,
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: "center",
    paddingBottom: "1rem",
  },
  subTitle: { height: 35 },
  bulletPoints: {
    paddingLeft: "1rem",
  },
});

export default function AccountPricingOptions() {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  const subscriptionType = user.account.subscription_type;

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.header}>
        Plans
      </Typography>

      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h3" component="h2">
                Free{" "}
                {subscriptionType === "Free" && (
                  <>
                    <br />
                    (active plan)
                  </>
                )}
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                className={classes.subTitle}
                variant="body2"
              >
                Try out the product
              </Typography>
              <Typography variant="body2">
                <ul className={classes.bulletPoints}>
                  <li>Your own hosted podcast</li>
                  <li>Publish an unlimited number of episodes</li>
                  <li>5000 characters per month</li>
                </ul>
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              {subscriptionType !== "Free" && (
                <Button variant="contained" size="small" href="/link-to-stripe">
                  Change to plan
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h3" component="h2">
                Standard{" "}
                {subscriptionType === "Standard" && (
                  <>
                    <br />
                    (active plan)
                  </>
                )}
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                className={classes.subTitle}
                variant="body2"
              >
                Suitable for most text podcasters
              </Typography>
              <Typography variant="body2">
                <ul className={classes.bulletPoints}>
                  <li>All the Free features</li>
                  <li>More characters per month</li>
                </ul>
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              {subscriptionType !== "Standard" && (
                <Button
                  variant="contained"
                  size="small"
                  href="/link-to-stripe"
                  disabled
                >
                  Coming soon
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h3" component="h2">
                Premium{" "}
                {subscriptionType === "Premium" && (
                  <>
                    <br />
                    (active plan)
                  </>
                )}
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                className={classes.subTitle}
                variant="body2"
              >
                Suitable for long form content or frequent releases
              </Typography>
              <Typography variant="body2">
                <ul className={classes.bulletPoints}>
                  <li>All the Standard features</li>
                  <li>Many more characters per month</li>
                </ul>
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              {subscriptionType !== "Premium" && (
                <Button
                  variant="contained"
                  size="small"
                  href="/link-to-stripe"
                  disabled
                >
                  Coming soon
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
