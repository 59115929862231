import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Container, Grid, CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { UserProvider } from "./contexts/UserProvider";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Routes from "./views/Routes";
import { FeedProvider } from "./contexts/FeedProvider";
import theme from "./theme";

export default function App() {
  return (
    <UserProvider>
      <FeedProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <Container maxWidth="md">
              <Grid container>
                <Navbar />
              </Grid>
              <Grid container>
                <Routes />
              </Grid>
              <Grid container>
                <Footer />
              </Grid>
            </Container>
          </Router>
        </ThemeProvider>
      </FeedProvider>
    </UserProvider>
  );
}
