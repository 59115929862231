import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: { marginTop: "5rem" },
  header: { marginBottom: "1.2rem" },
  card: {
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
    height: 286,
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: "center",
    paddingBottom: "1rem",
  },
  subTitle: { height: 35 },
  bulletPoints: {
    paddingLeft: "1rem",
  },
});

export default function PricingOptions() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.header}>
        Pricing
      </Typography>

      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h3" component="h2">
                Free
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                className={classes.subTitle}
                variant="body2"
              >
                Try out the product
              </Typography>
              <Typography variant="body2">
                <ul className={classes.bulletPoints}>
                  <li>Your own hosted podcast</li>
                  <li>Publish an unlimited number of episodes</li>
                  <li>5000 characters per month</li>
                </ul>
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                variant="contained"
                size="small"
                href="/login?signup=true&subscription_type=free"
              >
                Sign up
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h3" component="h2">
                Standard
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                className={classes.subTitle}
                variant="body2"
              >
                Suitable for most text podcasters
              </Typography>
              <Typography variant="body2">
                <ul className={classes.bulletPoints}>
                  <li>All the Free features</li>
                  <li>More characters per month</li>
                </ul>
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                variant="contained"
                size="small"
                href="/login?signup=true&subscription_type=standard"
                disabled
              >
                Coming soon
              </Button>
            </CardActions>
          </Card>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h3" component="h2">
                Premium
              </Typography>
              <Typography
                gutterBottom
                color="textSecondary"
                className={classes.subTitle}
                variant="body2"
              >
                Suitable for long form content or frequent releases
              </Typography>
              <Typography variant="body2">
                <ul className={classes.bulletPoints}>
                  <li>All the Standard features</li>
                  <li>Many more characters per month</li>
                </ul>
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button
                variant="contained"
                size="small"
                href="/login?signup=true&subscription_type=premium"
                disabled
              >
                Coming soon
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
