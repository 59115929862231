import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Box, TextField } from "@material-ui/core";
import { signupOrLogin } from "../api/signup_or_login";
import { useHistory } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formItem: {
    marginTop: "1rem",
  },
});

export default function LoginForm() {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const { loadUser } = useContext(UserContext);
  const { loadFeeds } = useContext(FeedContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const responseData = await signupOrLogin(email, password, false);
      loadUser();
      loadFeeds();
      history.push("/");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className={classes.root}
    >
      <TextField
        required
        id="email-input"
        label="Email"
        type="email"
        className={classes.formItem}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <TextField
        required
        id="password-input"
        label="Password"
        type="password"
        autoComplete="current-password"
        className={classes.formItem}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <Button type="submit" variant="contained" className={classes.formItem}>
        Login
      </Button>
    </Box>
  );
}
