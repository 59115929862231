import React from "react";
import LandingPageContent from "../components/LandingPageContent";
import PageContainer from "./PageContainer";

export default function HomePage() {
  return (
    <PageContainer>
      <LandingPageContent />
    </PageContainer>
  );
}
