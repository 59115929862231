import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import PageContainer from "./PageContainer";

const useStyles = makeStyles({
  textWrapper: {
    marginTop: "20vh",
  },
  statusCode: {
    fontSize: 54,
    textAlign: "center",
    marginBottom: 8,
  },
});

export default function FallbackPage() {
  const classes = useStyles();

  return (
    <PageContainer>
      <div className={classes.textWrapper}></div>
      <Typography variant="h2" component="h1" className={classes.statusCode}>
        404
      </Typography>
    </PageContainer>
  );
}
