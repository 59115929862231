import { makeAuthenticatedRequest } from "/app/src/api/base_requests";

export async function getFeeds() {
  return makeAuthenticatedRequest("api/feeds/", "GET");
}

export async function createFeed() {
  return makeAuthenticatedRequest("api/feeds/", "POST");
}

export async function updateFeedDetails(feed_uuid, body) {
  return makeAuthenticatedRequest(`api/feeds/${feed_uuid}/`, "PATCH", body);
}

export async function deleteFeed(feed_uuid) {
  return makeAuthenticatedRequest(`api/feeds/${feed_uuid}/`, "DELETE");
}
