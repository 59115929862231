import { BASE_URL } from "../constants";

export async function signupOrLogin(email, password, createAccount = false) {
  const url = `${BASE_URL}/api/login/`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: email,
      password: password,
      create_account: createAccount,
    }),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const responseData = await response.json();

  localStorage.setItem("access_token", responseData.access);
  localStorage.setItem("refresh_token", responseData.refresh);

  return responseData;
}

export async function refreshToken() {
  const refreshToken = localStorage.getItem("refresh_token");
  const url = `${BASE_URL}/api/token/refresh/`;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const responseData = await response.json();

  localStorage.setItem("access_token", responseData.access);

  return responseData.access;
}

export function logout() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  window.location.reload();
}

export function isLoggedIn(user) {
  const userIsNull = user === null;
  const accessTokenIsNull = localStorage.getItem("access_token") === null;
  return !userIsNull && !accessTokenIsNull;
}
