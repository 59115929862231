import React, { useContext } from "react";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles({
  wrapper: {
    height: "55px",
    width: "100%",
    marginBottom: "1rem",
  },
  linearProgress: {
    height: "0.5rem",
  },
});

function UsageProgressBar() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const usageCount = user.account.usage_count;
  const usageLimit = user.account.usage_limit;
  const progress = (usageCount / usageLimit) * 100;

  return (
    <Box className={classes.wrapper}>
      <Typography variant="body1">Monthly usage:</Typography>
      <LinearProgress
        className={classes.linearProgress}
        variant="determinate"
        value={progress}
      />
      <Typography variant="body2">
        {usageCount} / {usageLimit} characters
      </Typography>
    </Box>
  );
}

export default UsageProgressBar;
