import React from "react";
import { Route, Switch } from "react-router-dom";
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import FallbackPage from "./FallbackPage";
import FeedPage from "./FeedPage";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/feed/:uuid" component={FeedPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route component={FallbackPage} />
    </Switch>
  );
}
