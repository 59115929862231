import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Box,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formItem: {
    marginTop: "1rem",
  },
  previewImage: {
    width: "200px",
    marginTop: "1rem",
  },
  button: {
    marginTop: "1rem",
  },
});

export default function UpdateFeedDetailsForm({ onSubmit }) {
  const classes = useStyles();

  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage(file);

    // Preview the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create FormData to handle both the name and image upload
    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (image) {
      formData.append("image", image);
    }

    onSubmit(formData);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h3" gutterBottom>
          Feed details
        </Typography>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <TextField
            id="feed-name-input"
            label="Feed name"
            type="text"
            className={classes.formItem}
            value={name}
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            type="file"
            onChange={handleImageChange}
            inputProps={{ accept: "image/*" }}
            className={classes.formItem}
          />
          {preview && (
            <Box>
              <img
                src={preview}
                alt="Preview"
                className={classes.previewImage}
              />
            </Box>
          )}

          <Button
            type="submit"
            variant="contained"
            className={classes.formItem}
          >
            Update feed details
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
