import React, { useState, useEffect } from "react";
import FeedContext from "./FeedContext";
import {
  getFeeds,
  updateFeedDetails,
  createFeed,
  deleteFeed,
} from "../api/feed";

export const FeedProvider = ({ children }) => {
  const [feeds, setFeeds] = useState([]);
  const [activeFeedUuid, setActiveFeedUuid] = useState(null);

  const updateFeed = async (formData) => {
    try {
      const updatedFeed = await updateFeedDetails(activeFeedUuid, formData);
      setFeeds((prevFeeds) =>
        prevFeeds.map((feed) =>
          feed.uuid === activeFeedUuid ? updatedFeed : feed
        )
      );
    } catch (error) {
      console.error("Error updating feed:", error);
    }
  };

  const loadFeeds = async () => {
    try {
      const feedsData = await getFeeds();
      setFeeds(feedsData);
      if (feedsData.length > 0) {
        setActiveFeedUuid(feedsData[0].uuid);
      }
    } catch (error) {
      console.error("Error loading feeds:", error);
    }
  };

  const switchFeed = (uuid) => {
    if (feeds.some((feed) => feed.uuid === uuid)) {
      setActiveFeedUuid(uuid);
    } else if (uuid === "first" && feeds.length > 0) {
      setActiveFeedUuid(feeds[0].uuid);
    }
  };

  const newFeed = async () => {
    try {
      const newFeed = await createFeed();
      setFeeds((prevFeeds) => [...prevFeeds, newFeed]);
      return newFeed;
    } catch (error) {
      console.error("Error creating feed:", error);
    }
  };

  const removeFeed = async (uuid) => {
    try {
      await deleteFeed(uuid);
      setFeeds((prevFeeds) => prevFeeds.filter((feed) => feed.uuid !== uuid));
    } catch (error) {
      console.error("Error deleting feed:", error);
    }
  };

  useEffect(() => {
    loadFeeds();
  }, []);

  const activeFeed = feeds.find((feed) => feed.uuid === activeFeedUuid) || null;

  return (
    <FeedContext.Provider
      value={{
        feeds,
        activeFeed,
        loadFeeds,
        updateFeed,
        switchFeed,
        newFeed,
        removeFeed,
      }}
    >
      {children}
    </FeedContext.Provider>
  );
};
