import { makeAuthenticatedRequest } from "/app/src/api/base_requests";

export async function addEpisode(feed_uuid, body) {
  return makeAuthenticatedRequest(
    `api/feeds/${feed_uuid}/episodes/`,
    "POST",
    body
  );
}

export async function deleteEpisode(feed_uuid, episode_uuid) {
  return makeAuthenticatedRequest(
    `api/feeds/${feed_uuid}/episodes/${episode_uuid}/`,
    "DELETE"
  );
}

export async function listEpisodes(feed_uuid) {
  return makeAuthenticatedRequest(`api/feeds/${feed_uuid}/episodes/`, "GET");
}
