import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import EpisodeList from "../components/EpisodeList";
import FeedDetails from "../components/FeedDetails";
import { isLoggedIn } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import PageContainer from "./PageContainer";
import FallbackPage from "./FallbackPage";
import FeedContext from "../contexts/FeedContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  section: {
    marginBottom: "2rem",
  },
});

export default function FeedPage() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const { uuid } = useParams();
  const { switchFeed, newFeed, activeFeed } = useContext(FeedContext);
  const history = useHistory();

  useEffect(() => {
    if (uuid === "new") {
      newFeed().then((createdFeed) => {
        switchFeed(createdFeed.uuid);
        history.push(`/feed/${createdFeed.uuid}`);
      });
    } else if (uuid === "first") {
      switchFeed("first");
      if (activeFeed) {
        history.push(`/feed/${activeFeed.uuid}`);
      }
    } else {
      switchFeed(uuid);
    }
  }, [uuid, activeFeed]);

  return (
    <PageContainer>
      {isLoggedIn(user) ? (
        <>
          <div className={classes.section}>
            <FeedDetails />
          </div>
          <div className={classes.section}>
            <EpisodeList />
          </div>
        </>
      ) : (
        <FallbackPage />
      )}
    </PageContainer>
  );
}
