import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import AddEpisodeForm from "./AddEpisodeForm";
import { listEpisodes, addEpisode, deleteEpisode } from "../api/episode";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Skeleton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import FeedContext from "../contexts/FeedContext";
import UserContext from "../contexts/UserContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
  },
  item: {
    marginBottom: "1rem",
  },
  episodeTitle: {
    fontWeight: "bold",
  },
  episodeCard: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#e0e0e0",
    borderWidth: "1px",
    boxShadow: "none",
  },
  deleteButton: {
    padding: 8,
  },
  audioPlayerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  audioPlayer: {
    flexGrow: 1,
    marginRight: "1rem",
    height: "30px",
  },
});

const MAX_TEXT_LENGTH = 150; // Adjust this value as needed

export default function EpisodeList() {
  const classes = useStyles();

  const { loadUser } = useContext(UserContext);
  const { activeFeed } = useContext(FeedContext);
  const [episodes, setEpisodes] = useState([]);
  const [addingEpisode, setAddingEpisode] = useState(false);

  const fetchEpisodes = async () => {
    try {
      const responseData = await listEpisodes(activeFeed.uuid);
      setEpisodes(responseData);
    } catch (error) {
      console.error("Error fetching episodes:", error);
    }
  };

  const handleDelete = async (episode_uuid) => {
    try {
      await deleteEpisode(activeFeed.uuid, episode_uuid);
      fetchEpisodes();
    } catch (error) {
      console.error("Error deleting episode:", error);
    }
  };

  const handleSubmitEpisode = async (formData) => {
    addEpisode(activeFeed.uuid, formData)
      .then((data) => {
        fetchEpisodes();
        loadUser();
        setAddingEpisode(false);
      })
      .catch((error) => {
        // TODO: Display a popup that it failed for some reason
        fetchEpisodes();
        loadUser();
        setAddingEpisode(false);
      });
    // Show a loading episode right after adding episode
    setAddingEpisode(true);
  };

  useEffect(() => {
    if (activeFeed === null) {
      return;
    }
    fetchEpisodes();
  }, [activeFeed]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h3" gutterBottom className={classes.item}>
          Episodes
        </Typography>
        <div className={classes.item}>
          <AddEpisodeForm
            onSubmit={handleSubmitEpisode}
            addingEpisode={addingEpisode}
          />
        </div>
        {addingEpisode && (
          <div className={classes.item}>
            <Card className={classes.episodeCard}>
              <CardContent>
                <Skeleton variant="text" width={210} height={30} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="70%" height={30} />
              </CardContent>
            </Card>
          </div>
        )}
        {episodes.map((episode) => (
          <div className={classes.item} key={episode.title}>
            <Card className={classes.episodeCard}>
              <CardContent>
                <Typography className={classes.episodeTitle}>
                  {episode.title}
                </Typography>
                <Typography color="textSecondary">
                  {new Date(episode.created_date).toLocaleDateString()}
                </Typography>
                <Typography>
                  {episode.text.length > MAX_TEXT_LENGTH
                    ? `${episode.text.substring(0, MAX_TEXT_LENGTH)}...`
                    : episode.text}
                </Typography>
                <div className={classes.audioPlayerContainer}>
                  {episode.audio_url && (
                    <audio controls className={classes.audioPlayer}>
                      <source src={episode.audio_url} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => handleDelete(episode.uuid)}
                    size="small"
                    aria-label="delete"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
