import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Box, TextField } from "@material-ui/core";
import { signupOrLogin } from "../api/signup_or_login";
import { useHistory } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import FeedContext from "../contexts/FeedContext";

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formItem: {
    marginTop: "1rem",
  },
});

function validatePassword(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  //const hasNumbers = /\d/.test(password);
  //const hasSpecialChar = /[\^$*.\[\]{}()?\-"!@#%&/,><':;|_~`]/.test(password);

  return password.length >= minLength && hasUpperCase && hasLowerCase;
}

export default function Signup() {
  const classes = useStyles();
  const history = useHistory();

  const { loadUser } = useContext(UserContext);
  const { loadFeeds } = useContext(FeedContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionType = urlParams.get("subscription_type");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Check if passwords match
    if (password !== verifyPassword) {
      setErrorMessage("Passwords do not match. Please try again.");

      setPassword(""); // Clear the password field
      setVerifyPassword(""); // Clear the verify password field
      return; // Stop the handleSubmit function if passwords do not match
    }

    // Check password complexity
    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long and include an uppercase and a lowercase letter."
      );
      return;
    }
    try {
      await signupOrLogin(email, password, true);
      if (subscriptionType == "standard") {
        console.log("TODO: Redirect to stripe page for standard subscription");
        return;
      } else if (subscriptionType == "premium") {
        console.log("TODO: Redirect to stripe page for premium subscription");
        return;
      }
      loadUser();
      loadFeeds();
      history.push("/");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className={classes.root}
    >
      {errorMessage && (
        <div style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</div>
      )}
      <TextField
        required
        id="email-input"
        label="Email"
        type="email"
        className={classes.formItem}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <TextField
        required
        id="password-input"
        label="Password"
        type="password"
        autoComplete="new-password"
        className={classes.formItem}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <TextField
        required
        id="password-verify-input"
        label="Verify Password"
        type="password"
        autoComplete="new-password"
        className={classes.formItem}
        value={verifyPassword}
        onChange={(e) => setVerifyPassword(e.target.value)}
      />

      <Button type="submit" variant="contained" className={classes.formItem}>
        {"Create account"}
      </Button>
    </Box>
  );
}
