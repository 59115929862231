import React from "react";
import { makeStyles } from "@material-ui/styles";
import { headerHeight, footerHeight, topMargin } from "../constants";

const useStyles = makeStyles({
  root: {
    marginTop: topMargin,
    height: "100%",
    width: "100%",
    minHeight: `calc(100vh - ${headerHeight}px - ${footerHeight}px - ${topMargin}px)`,
  },
});

export default function PageContainer({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}
