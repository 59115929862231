import React, { useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Typography } from "@material-ui/core";
import { logout } from "../api/signup_or_login";
import UserContext from "../contexts/UserContext";
import UsageProgressBar from "../components/UsageProgressBar";
import AccountPricingOptions from "../components/AccountPricingOptions";

const useStyles = makeStyles({
  header: {
    marginLeft: 0,
    marginBottom: "1rem",
  },
});

export default function AccountDetails() {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <>
      <Typography variant="h3" className={classes.header}>
        Logged in as {user.email}
      </Typography>
      <UsageProgressBar />
      <AccountPricingOptions />
      <Button variant="contained" onClick={logout}>
        Log out
      </Button>
    </>
  );
}
